// Generated by Framer (aab6bf9)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { TruncatedText } from "../codeFile/TruncatedText.tsx";
import * as localizedValues from "./kLCl2cGkg-0.js";
const RichTextTruncatedText = TruncatedText(RichText);

const cycleOrder = ["kDCWsgAD2"];

const serializationHash = "framer-2EJIa"

const variantClassNames = {kDCWsgAD2: "framer-v-18ypi9o"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translateX(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, title, width, ...props}) => { return {...props, GtUOkt9dq: title ?? props.GtUOkt9dq ?? "Title"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, GtUOkt9dq, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "kDCWsgAD2", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-18ypi9o", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"kDCWsgAD2"} ref={ref ?? ref1} style={{...style}}><RichTextTruncatedText __fromCanvasComponent children={<React.Fragment><motion.h2 style={{"--font-selector": "R0Y7UG9wcGlucy03MDA=", "--framer-font-family": "\"Poppins\", \"Poppins Placeholder\", sans-serif", "--framer-font-size": "28px", "--framer-font-weight": "700", "--framer-line-height": "1.4em", "--framer-text-color": "var(--extracted-1of0zx5, rgb(51, 51, 51))"}}>Title</motion.h2></React.Fragment>} className={"framer-1r1o1nr"} data-framer-name={"Title"} fonts={["GF;Poppins-700"]} layoutDependency={layoutDependency} layoutId={"O8hGrgVWt"} style={{"--extracted-1of0zx5": "rgb(51, 51, 51)"}} text={GtUOkt9dq} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2EJIa.framer-11mbkok, .framer-2EJIa .framer-11mbkok { display: block; }", ".framer-2EJIa.framer-18ypi9o { height: 118px; overflow: hidden; position: relative; width: 598px; }", ".framer-2EJIa .framer-1r1o1nr { flex: none; height: auto; left: 50%; position: absolute; top: 0px; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 118
 * @framerIntrinsicWidth 598
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"GtUOkt9dq":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerkLCl2cGkg: React.ComponentType<Props> = withCSS(Component, css, "framer-2EJIa") as typeof Component;
export default FramerkLCl2cGkg;

FramerkLCl2cGkg.displayName = "TruncatedText";

FramerkLCl2cGkg.defaultProps = {height: 118, width: 598};

addPropertyControls(FramerkLCl2cGkg, {GtUOkt9dq: {defaultValue: "Title", title: "Title", type: ControlType.String}} as any)

addFonts(FramerkLCl2cGkg, [{explicitInter: true, fonts: [{family: "Poppins", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLCz7V15vFP-KUEg.woff2", weight: "700"}]}], {supportsExplicitInterCodegen: true})